<template>
  <div class="page">
    <div class="view">
      <div class="viewT">
        <div style="font-weight: bold;">新建模版</div>
        <el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
      </div>
      <div class="viewC">
        <div class="viewCL">
          <el-form class="demo-ruleForm" id="addHuodong" size="small">
            <el-form-item label="模版名称" prop="remark" label-width="70px">
              <el-input type="input" v-model="temName" size="small" placeholder="请输入模版名称（最大20字符）"></el-input>
            </el-form-item>
            <el-form-item label="模版类型" prop="temType" label-width="70px">
              <el-radio-group v-model="temType">
                <el-radio :label="2">通知</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="模版内容" prop="temContent" label-width="70px">
              <el-input type="textarea" v-model="temContent" resize="none" style="width: 400px;" rows="4" size="small"
                placeholder="请输入内容"></el-input>
            </el-form-item>
            <div class="typeView">
              当前模版 预计发送条数约为{{ Math.ceil(temContent.length / 68) }}条短信,(结尾请使用拒收回复R)
            </div>
            <el-form-item label="申请说明" prop="remark" label-width="70px">
              <el-input type="textarea" v-model="remark" resize="none" style="width: 400px;" rows="4" size="small"
                placeholder="请输入说明"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="viewB">
        <el-button type="primary" style="width: 110px;margin-right: 20px;" @click="handleSubmitClick">提交
        </el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      temName: '',
      temType: 2,
      temContent: '',
      remark: '',
      temId: this.$route.query.temId,
      row:this.$route.query.row

    }
  },
  mounted() {
    if (this.row) {
     this.temName =this.row.temName
     this.temContent =this.row.temContent
     this.remark =this.row.remark
     this.temType=this.row.temType
    }

  },
  methods: {
    //提交
    handleSubmitClick() {
      if (this.temName == '') {
        this.$message({
          type: 'error',
          message: '请输入名称'
        })
        return
      }
      if (this.temContent == '') {
        this.$message({
          type: 'error',
          message: '请填写模版内容'
        })
        return
      }
      let params = {
        "temName": this.temName,
        "temType": 2,
        "temContent": this.temContent,
        "paramJson": null,
        "extend": null,
        "remark": this.remark,
        "temId": this.temId ? this.temId : null
      }
      this.$http.post("/cms/api/ZthySms/CreateTemplate?companyId=" + this.$store.state.userInfo.companyInfo.id, params).then(res => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '添加模版成功，请等待审核'
          })
          this.$router.go(-1);
        }
      })
    },
    //返回
    handleBackClick() {
      this.$confirm('是否返回上一页?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.go(-1);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
  color: $fontColor;

  .view {
    background: #fff;
    height: 100%;

    border-radius: 20px;
    display: flex;
    flex-direction: column;

    .viewT {
      box-sizing: border-box;
      height: 68px;
      display: flex;
      align-items: center;
      border-bottom: solid 1px #F2F2F2;
      padding: 0 20px;
      justify-content: space-between;
    }

    .viewC {
      flex: 1;
      display: flex;
      height: calc(100% - 136px);

      .viewCL {
        width: 582px;
        box-sizing: border-box;
        border-right: solid 1px #F2F2F2;
        overflow-y: scroll;
        padding-left: 22px;
        padding: 20px 0 20px 22px;
      }

      .viewCR {
        flex: 1;
        padding: 20px 32px;
        overflow-y: scroll;

        .comClass {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin: 10px 0;

          .comTitle {
            margin-right: 10px;
          }
        }

        .comView {
          width: 94%;
          min-height: 120px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 20px;
          padding: 10px 10px 0 10px;

          .comItem {
            margin-right: 6px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            padding: 0 6px 0 10px;
            background: #FFF0E0;
            border: 1px solid #F77E04;
            line-height: 22px;
            font-size: 12px;
            color: #F77E04;
            display: inline-block;
            border-radius: 20px;

            span:nth-of-type(1) {
              margin-right: 10px;
            }
          }
        }
      }

      .viewCL::-webkit-scrollbar {
        display: none;
      }

      .viewCR::-webkit-scrollbar {
        display: none;
      }
    }


    .viewB {
      height: 68px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
      border-top: solid 1px #F2F2F2;
    }


  }
}
</style>
